import './bootstrap.scss';

// Importing Bootstrap JS
import * as bootstrap from 'bootstrap';

// Make htmx globally available
import htmx from 'htmx.org';
(window as any).htmx = htmx;

console.log('Novi Connect - %s', process.env.NODE_ENV)
